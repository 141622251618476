#basicDetails_name {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  color: #272D4E;
}

#basicDetails_location_div {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #94A2B3;
}

#basicDetails_tags_div {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #94A2B3;
}

#basicDetails_bio_div {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #94A2B3;
}

.basicDetails_actionBtns_div {
  padding: "10px";
}

#profileGraphicalDetails {
  padding-top: 20px;
}

.tabTitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #212121;
}

.graphicalDetails_title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #94A2B3;
}

.graphicalDetails_highlightedText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
}

.graphicalDetails_subtext {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 6px;
  color: #94A2B3;
}

.extendedDetailsCard_title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #94A2B3;
}

#extendedDetailsCard_followersCredibility_highlightedText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  color: #000000;
}

.list-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  color: #333333;
}

.list-item {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #4F4F4F;
}

.timelineTitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #333333;
  border-top: 1.5px solid #000000;
  border-bottom: 1.5px solid #000000;
  width: 100%;
  display: block;
  /* border-bottom: "1.5px solid #000000"; */
}

.timeline-itemOnLeft {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}

.timeline-heading {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #333333;
  }

.timeline-subheading {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #4F4F4F;
}

.timeline-subheadingDesc {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #2F80ED;
}

.timeline-website {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #2D9CDB;
}

.timeline-description {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #4F4F4F;
}

.list-data {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  text-align: center;
  color: #000000;
}