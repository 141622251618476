.wrapperClass {
  padding: 1rem;
  border: 1px solid #ccc;
}
.editorClass {
  background-color: lightgray;
  padding: 1rem;
  border: 1px solid #ccc;
}
.toolbarClass {
  border: 1px solid #ccc;
}

.textEditor {
  border: 1px solid lightgray;
  padding: 10px;
  height: 300px;
  overflow: hidden auto;
}

.editor::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 600px) {
  .dialogWrapper {
    padding: 20px 6px !important;
  }
}
