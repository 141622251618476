.filter-component {
  border: 1px solid #7B809A;
  border-radius: 8px;
  padding: 5px;
}

.filter-close-icon {
  height: 20px;
}

.appliedFilters-label {
  padding-right: 20px;
}

.menuIcon {
  color: #b5b5c3;
}

.menuitem {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.87);
}

.subtext {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #b5b5c3;
}

.infScore {
  color: #007DFF;
}

.containsTooltip {
  cursor: pointer;
}

.profileHeading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 25px;
  color: #272d4e;
}

.location {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #94a2b3;
}

.search-heading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #212121;
}

.search-subtext {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  color: #B5B5C3;
}